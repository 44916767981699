<template>
  <div class="card">
    <h5>Trading Chart</h5>
    <Chart type="bar" :data="tradeChart" />
  </div>
</template>

<script>
export default {
  props: {
    tradeChart: {
      type: Object,
    },
  },
}
</script>

<style scoped>
.card {
  box-shadow: none !important;
}
</style>
