<template>
{{ getDisplayValue }}
</template>

<script>
export default {
  name: 'OutputDecimals',
  props: {
    decimalsValue: {
      type: String
    },
    decimals:{
      type: Number
    }
  },
  computed:{
    getDisplayValue()
    {
      if (this.decimals === 0 || this.decimals === null || this.decimals === undefined) return this.decimalsValue;
      if (this.decimalsValue.length !== 0)
      {
        if (this.decimalsValue.length > this.decimals)
        {
          return this.decimalsValue.slice(0, -this.decimals) + '.' +
            this.decimalsValue.slice(-this.decimals);
        }
        else
        {
          return '0.' +
            '0'.repeat(this.decimals - this.decimalsValue.length) +
            this.decimalsValue;
        }
      }
      return this.decimalsValue
    }
  }
}

</script>

<style scoped>

</style>