<template #title>
  <div
    class="surface-0 flex align-items-center justify-content-center overflow-hidden"
  >
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
      <div class="col-12 mt-5 xl:mt-0 text-center">
        <img
          :src="'layout/images/logo-' + logoColor + '.svg'"
          alt="Sakai logo"
          class="mb-5"
          style="width: 81px; height: 60px"
        />
      </div>
      <div
        class="col-12 xl:col-6"
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color),
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="h-full w-full m-0 py-3 px-4"
          style="
            border-radius: 53px;
            background: linear-gradient(
              180deg,
              var(--surface-50) 38.9%,
              var(--surface-0)
            );
          "
        >
          <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">Autentic</div>
            <span class="text-600 font-medium"
              >Fill in all the fields for registration</span
            >
            <div class="col-12 md:col-3"></div>
          </div>
          <div class="w-full md:w-10 mx-auto">
            <Toast position="top-center" group="info" />
            <div
                class="font-medium mb-2 text-dm"
                style="color: red"
                v-for="error of v$.$errors"
                :key="error.$uid"
            >
              <small>{{ error.$message }}</small>
              <small v-if="error.$validator === 'noStrong'"
              >{{ error.$message }} The password is weak</small
              >
            </div>
            <label for="email" class="block text-900 text-xl font-medium mb-2"
            >Email
            </label>
            <InputText
                id="email"
                v-model.trim="email"
                type="text"
                class="w-full mb-3"
                placeholder="Email *"
                style="padding: 1rem"
            />

            <label for="password" class="block text-900 font-medium text-xl mb-2"
            >Password</label
            >
            <Password
                id="password"
                v-model.trim="password"
                placeholder="Password *"
                :toggleMask="true"
                class="w-full mb-3"
                inputClass="w-full"
                inputStyle="padding:1rem"
            >
            </Password>
            <label for="password_confirm" class="block text-900 font-medium mb-2"
            >Password confirm
            </label>
            <Password
                id="password_confirm"
                v-model="password_confirm"
                placeholder="Password confirm *"
                :toggleMask="true"
                :feedback="false"
                class="w-full mb-3"
                inputClass="w-full"
                inputStyle="padding:1rem"
            >
            </Password>
            <p
                v-if="!isPasswordConfirm"
                class="font-medium text-sm"
                style="color: red"
            >
              Passwords don't match
            </p>
            <p v-else class="font-medium text-sm" style="color: green">
              Passwords match
            </p>

            <label for="fName" class="block text-900 text-xl font-medium mb-2"
            >Second Name</label
            >
            <InputText
                id="fName"
                v-model="fName"
                type="text"
                class="w-full mb-3"
                placeholder="Second Name *"
                style="padding: 1rem"
            />

            <label for="iName" class="block text-900 text-xl font-medium mb-2"
            >First Name</label
            >
            <InputText
                id="iName"
                v-model="iName"
                type="text"
                class="w-full mb-3"
                placeholder="First Name *"
                style="padding: 1rem"
            />

            <label for="oName" class="block text-900 text-xl font-medium mb-2"
            >Patronymic Name</label
            >
            <InputText
                id="oName"
                v-model="oName"
                type="text"
                class="w-full mb-3"
                placeholder="Patronymic Name"
                style="padding: 1rem"
            />
            <div class="flex align-items-center justify-content-end mb-5">
              <router-link
                  class="font-medium no-underline ml-2 text-right cursor-pointer"
                  style="color: var(--primary-color)"
                  :to="'/login'"
              >Login</router-link
              >
            </div>
            <Button
                @click="register"
                :disabled="isFormRegisterConfirm === false"
                label="Register"
                class="w-full p-3 text-xl"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {email, minLength, required} from "@vuelidate/validators";

export default {
  name: 'Register',
  head() {
    return {
      title: 'Register',
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      email: '',
      password: '',
      password_confirm: '',
      passwordMismatch: '',
      fName: '',
      iName: '',
      oName: '',
      checked: false,
      isError: false,
      message: '',
    }
  },
  validations() {
    return {
      email: { required, email },
      password: {
        required,
        minLength: minLength(8),
        noStrong: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          // const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return (
              containsUppercase && containsLowercase && containsNumber
              // && containsSpecial
          )
        },
      },
    }
  },
  methods: {
    async register() {
      this.v$.$validate()
      if (!this.v$.$error) {
        const formData = {
          email: this.email,
          password: this.password,
          fName: this.fName,
          iName: this.iName,
          oName: this.oName,
        }
        const headers = {
          accept: 'application/json',
          'Content-Type': 'application/json',
        }
        try {
          const result = await this.axios.post(
              '/api/users/register',
              formData,
              {
                headers,
              }
          )
          console.log(result)
          this.showSuccess()
          setTimeout(() => this.$router.push('/login'), 5000)
        } catch (error) {
          this.isError = true
          if (error.response.status === 400) {
            this.message = 'Mail already exists or Invalid input'
            this.showError()
          } else if (error.response.status === 422) {
            this.message = 'Unprocessable entity'
            this.showError()
          }
          console.log(error)
        }
      }
    },
    showSuccess() {
      this.$toast.add({
        severity: 'success',
        summary: 'Congratulations on registering!',
        detail: 'Please check your E-mail',
        group: 'info',
        life: 4000,
      })
    },
    showError() {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: this.message,
        group: 'info',
        life: 4000,
      })
    },
    test() {
      console.log(this.$auth)
      console.log(this.$auth.check())
      console.log(this.$auth.check('admin'))
      console.log(this.$auth.user())
      this.axios.get('/api/')
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return 'white'
      return 'dark'
    },
    isPasswordConfirm() {
      return (
          this.password.length >= 8 && this.password === this.password_confirm
      )
    },
    isFormRegisterConfirm() {
      return (
          this.password.length >= 8 &&
          !this.v$.$error &&
          this.fName.length > 0 &&
          this.iName.length > 0 &&
          this.password === this.password_confirm
      )
    },
  },
  watch: {
    password() {
      this.v$.$touch()
      return
    },
    password_confirm() {
      if (this.password === this.password_confirm) {
        this.passwordMismatch = false
      }
    },
    email() {
      this.v$.$touch()
      return
    },
  },
}
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>
