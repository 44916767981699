<template>
  <div class="trade-list">
    <h6>Trade list</h6>
    <DataTable
      :value="tradeList"
      scrollable
      scrollHeight="58vh"
      class="text-xs"
      dataKey="id"
      responsiveLayout="scroll"
    >
      <Column class="p-1" header="price" field="price" ></Column>
      <Column class="p-1" header="value" field="value" ></Column>
      <Column class="p-1" header="time" field="displayTime"> </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  props: {
    tradeList: {
      type: Object,
    },
  },
}
</script>

<style scoped>
h6 {
  text-align: center;
  margin: 5px;
}
</style>
