<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" :src="topbarImage()" />
      <span>Autentic</span>
    </router-link>
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
      <!--li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
			</li-->
      <!--li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li-->
        <div class="leftalign">
        <span>{{ $auth.user() ? $auth.remember() : 'Email' }}</span>
          <div class="dropdown">
          <i class="pi pi-user dropbtn"></i>
         <div class="dropdown-content">
           <a href="./profile">Профиль</a>
           <a href="#" @click="logout">Выход</a>
         </div>
        </div>
        </div>
	</div>
</template>

<style>

.leftalign{
  margin-left:auto;
}

.dropbtn {
  color: rgb(0, 0, 0);
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 1px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

</style>

<script>
export default {
  methods: {
    logout() {
      this.$auth.logout()
      this.$router.push('/login')
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },
    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event)
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? 'images/logo-white.svg'
        : 'images/logo-dark.svg'
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme
    },
  },
}
</script>
