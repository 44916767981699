<template>
  <div class="trade-market">
    <h6>Trade Market</h6>

    <div class="block-market">
      <div class="p-inputgroup">
        <InputText
          v-model="valueLimitBuy"
          class="input m-1"
          placeholder="Enter Value"
        />
        <p v-if="!allFieldBuy" class="p-error mx-2">
          Empty field, too large or incorrect value
        </p>
        <Button
          :disabled="!allFieldBuy"
          class="btn p-button-success"
          label="Buy"
          @click="buy"
        />
      </div>

      <hr />
      <div class="p-inputgroup">
        <InputText
          class="input m-1"
          v-model="valueLimitSale"
          placeholder="Enter Value"
        />
        <p v-if="!allFieldSale" class="p-error mx-2">
          Empty field, too large or incorrect value
        </p>
        <Button
          :disabled="!allFieldSale"
          class="btn p-button-danger"
          label="Sale"
          @click="sale"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valueLimitBuy: '',
      valueLimitSale: '',
      limitBuy: {
        valueLimitBuy: '',
      },
      limitSale: {
        valueLimitSale: '',
      },
    }
  },
  methods: {
    buy() {
      this.limitBuy.valueLimitBuy = this.valueLimitBuy
    },
    sale() {
      this.limitSale.valueLimitSale = this.valueLimitSale
    },
  },
  computed: {
    allFieldBuy() {
      return +this.valueLimitBuy > 0
    },
    allFieldSale() {
      return +this.valueLimitSale > 0
    },
  },
}
</script>

<style scoped>
h6 {
  text-align: center;
  margin: 5px;
}
.block-market {
  display: flex;
  flex-wrap: nowrap;
}
.p-inputgroup {
  display: inline-block;
}
.input {
  width: 98%;
}
.btn {
  margin: 4px;
  width: 98%;
  border-radius: 6px;
}
</style>
