<template>
  <h6>Order Book</h6>
  <div class='header'>
    <div class='half-header'>Price({{orderPrice.tokenQuote}})</div>
    <div class='next-header'>Size({{orderPrice.token}})</div>
  </div>

  <div class="trade-pairs trade-size" style='overflow-y: hidden'>
    <div class='trade-reverse' style='background: #f3b9b9'>
      <div v-for='item in orderBooksSale' :key='item.price' class='trade-inline'>
        <div class='half-size order-text'><b>{{item.price}}</b></div>
        <div class='order-text'><b>{{item.size}}</b></div>
      </div>
    </div>
  </div>

  <div class='div-border'>
    Price: {{ priceString }}
  </div>

  <div class='trade-size' style='background: #A3E2C6; overflow-y: hidden'>
      <div v-for='item in orderBooksBuy' :key='item.price' class='trade-inline'>
        <div class='half-size order-text'><b>{{item.price}}</b></div>
        <div class='order-text'><b>{{item.size}}</b></div>
      </div>
  </div>

</template>

<script>
export default {
  props: {
    orderPrice: {
      type: Object,
    },
    orderBooksSale: {
      type: Array,
    },
    orderBooksBuy: {
      type: Array,
    },
  },
  data() {
    return {}
  },
  computed:{
    priceString()
    {
      let direction = '~';
      if (this.orderPrice.currentPrice - this.orderPrice.lastPrice < 0) direction = '🠗';
      if (this.orderPrice.currentPrice - this.orderPrice.lastPrice > 0) direction = '🠕';
      return this.orderPrice.currentPrice + ' ' + direction;
    },
  }
}
</script>

<style scoped>
.trade-size{
  height: 36%;
}
.trade-reverse
{
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
}
.trade-inline{
  display:flex;
  flex-direction: row;
  border-bottom: 1px lightgrey solid;
  border-top: 1px lightgrey solid;
}
.half-size{
  width: 50%;
  border-right: 2px lightgrey solid;
}
.div-border
{
  border: solid black 1px;
  text-align: center;
}
.order-text
{
  padding: 0 3px;
  font-size: 10px;
}
.header{
  display:flex;
  flex-direction: row;
  border: 1px black solid;
}
.half-header{
   width: 50%;
   border-right: 1px black solid;
  padding: 0 0;
  text-align: center;
  font-size: smaller;
 }
.next-header{
  width: 50%;
  padding: 0 0;
  text-align: center;
  font-size: smaller;
}
h6 {
  text-align: center;
  margin: 3px;
}
</style>
