<template>
  <div>
    <div>
      <div v-if="!is2FaEnabled" class="enable">
        <div class="links">
          <p class="red flex justify-content-center">2FA is disable</p>
        </div>
        <div class="img">
          <img :src="imgURL" alt="2FA"/>
        </div>

        <div class="attention">
          <p class="red">Attention!</p>
          <p>1. Download Google Authenticator to your phone</p>
          <p>2. Scan the QR code from the picture with it</p>
          <p>3. Make sure that Google Authenticator is connected and you see: "Autentic: your mail"</p>
          <p>4. Only after that click the "Enable 2FA veryfication" button</p>
        </div>

        <div class="links">
          <Button class="button items" label="Enable 2FA veryfication" @click="enable2fa"/>
        </div>
      </div>
      <div v-if="is2FaEnabled" class="disable">
        <div class="links">
          <p class="green flex justify-content-center">2FA is enabled</p>
        </div>
        <div class="links">
          <p class="red">We do not recommend disabling 2FA</p>
        </div>
        <div class="links">
          <Button
              class="button items p-button-danger"
              label="Disable 2FA"
              @click="disable2fa"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: 'Edit2fa',
  data() {
    return {
      is2FaEnabled: '',
      imgURL: '',
      user: ''
    }
  },
  mounted() {
    this.checkIs2FaEnabled()
  },
  methods: {
    async checkIs2FaEnabled () {
      const resp = await this.axios.get(`/api/users/${this.$auth.user().id}`)
      const user = resp.data
      this.user = user
      this.is2FaEnabled = user.is2FaEnabled

      if (!this.is2FaEnabled) {
        this.load2fa()
      }
    },
    async load2fa() {
      const create_token = await this.axios.get(`/api/users/${this.user.id}/create_token`)
      const token2fa = create_token.data.token2fa
      const urlImg = `otpauth://totp/${encodeURIComponent(`Autentic: ${this.user.email}`)}?secret=${token2fa}`
      // const token2fa = 'HAKJWBYHIXSNEDVCPMLRATBCTNS6GNR74XHXDSW7MIITS3TDQNSQ'

      QRCode.toDataURL(urlImg)
          .then((url) => {
            this.imgURL = url
          })
          .catch((err) => {
            console.log(err)
          })
    },
    async enable2fa() {
      const formData = {
        is2FaEnabled: true
      }
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/merge-patch+json',
      }
      try {
        await this.axios.patch(
            `/api/users/${this.user.id}`,
            formData,
            {
              headers,
            }
        )
        this.showSuccessEnable()
        location.reload()
      } catch (error) {
        if (error.response.status === 400) {
          this.message = 'Invalid input'
          this.showError()
        } else if (error.response.status === 422) {
          this.message = 'Unprocessable entity'
          this.showError()
        } else {
          this.message = 'Resource not found'
          this.showError()
        }
        console.log(error)
      }
    },
    async disable2fa() {
      const formData = {
        is2FaEnabled: false
      }
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/merge-patch+json',
      }
      try {
        await this.axios.patch(
            `/api/users/${this.user.id}`,
            formData,
            {
              headers,
            }
        )
        this.showSuccessDisable()
        location.reload()
      } catch (error) {
        if (error.response.status === 400) {
          this.message = 'Invalid input'
          this.showError()
        } else if (error.response.status === 422) {
          this.message = 'Unprocessable entity'
          this.showError()
        } else {
          this.message = 'Resource not found'
          this.showError()
        }
        console.log(error)
      }
    },
    showSuccessEnable() {
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: '2FA is enabled',
        group: 'info',
        life: 4000,
      })
    },
    showSuccessDisable() {
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: '2FA is disabled',
        group: 'info',
        life: 4000,
      })
    },
    showError() {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: '',
        group: 'info',
        life: 4000,
      })
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return 'white'
      return 'dark'
    },
  },
}
</script>

<style scoped>
.attention {
  text-align: center
}

</style>

