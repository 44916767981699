<template>
  <div class="trade-history">
    <TabView class="text-xs" ref="tabview">
      <TabPanel :header="getOrderHeader">
        <DataTable
          :value="tradeOrders"
          class="text-xs"
          dataKey="id"
          responsiveLayout="scroll"
          :rowClass='rowClass'
        >
          <Column
            class="p-1 px-2 text-pink-500"
            field="id"
            header="id"
          ></Column>
          <Column class="p-1"  field="displayTime" header="date"></Column>
          <Column class="p-1" field="mode" header="target">
            <template #body='slot'>
              <span :class='slot.data.mode==="sale" ? "text-pink-500" : "text-green-600"'>
                {{slot.data.mode}}
              </span>
            </template>
          </Column>
          <Column class="p-1" field="type" header="type">
            <template #body='slot'>
              <div v-if='slot.data.isCancelRequest === false'>{{slot.data.type}}</div>
              <div v-else>
                Delete progress
                <ProgressBar :value='slot.data.deleteProgress'/>
              </div>
            </template>
          </Column>
          <Column class="p-1" field="amount" header="amount"></Column>
          <Column class="p-1" field="priceString" header="price"></Column>
          <Column class="p-1" field="amountProcent" header="fill"></Column>
          <Column class='bg-white border-none flex flex-nowrap justify-content-end'>
            <template #body='slot'>
              <Button
                :disabled='!(slot.data.isCancelRequest === false)'
                icon="pi pi-times"
                class="p-button-rounded p-button-sm p-button-text"
                style='height:20px'
                @click="deleteOrder(slot.data)"
              />
            </template>
          </Column>
        </DataTable>
      </TabPanel>

      <TabPanel :header="getHistoryHeader">
        <DataTable
          :value="tradeHistory"
          :paginator='true'
          :rows='10'
          class="text-xs"
          dataKey="id"
          responsiveLayout="scroll"
        >
          <Column
            class="p-1 px-2 text-teal-500"
            field="id"
            header="id"
          ></Column>
          <Column class="p-1"  field="createdAt" header="date"> </Column>
          <Column class="p-1" field="amount" header="amount"></Column>
          <Column class="p-1" field="priceString" header="price"></Column>
        </DataTable>
      </TabPanel>

      <TabPanel :header='getTasksHeader'>
        <DataTable
          :value="tasksList"
          class="text-xs"
          dataKey="id"
          responsiveLayout="scroll"
        >
          <Column
            class="p-1 px-2 text-indigo-500"
            field="id"
            header="id"
          ></Column>
          <Column class="p-1"  field="displayTime" header="date"></Column>
          <Column class="p-1" field="mode" header="target">
            <template #body='slot'>
              <span :class='slot.data.mode==="sale" ? "text-pink-500" : "text-green-600"'>
                {{slot.data.mode}}
              </span>
            </template>
          </Column>
          <Column class="p-1" field="amount" header="amount"></Column>
          <Column class="p-1" field="priceString" header="price"></Column>
        </DataTable>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
export default {
  props: {
    tradeHistory: {
      type: Object,
    },

    tradeOrders: {
      type: Object,
    },

    tasksList: {
      type: Object,
    },
  },
  data(){
    return{
      test: 0,
    }
  },

  methods: {
    rowClass(value) {
      return value.isCancelRequest ? 'delete-mark' : null;
    },

    async deleteOrder(value){
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const params = {
        type: 'token_order_cancel',
        user: `/api/users/${this.$auth.user().id}`,
        auOrder: `/api/au_orders/${value.id}`,
        description: 'delete order',
        value: '0',
        address: '0',
        isTask: true
      }

      try{
        await this.axios.post('/api/token_events', params, headers);
        this.$emit('delete-order',value.id);
      }
      catch (e)
      {
        console.log(e);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Delete error',
          group: 'info',
          life: 3000,
        })
      }
    },
  },

  computed:{
    getOrderHeader(){
      return 'Orders (' + this.tradeOrders.length + ')';
    },
    getHistoryHeader(){
      return 'History (' + this.tradeHistory.length + ')';
    },
    getTasksHeader(){
      return 'Tasks (' + this.tasksList.length + ')';
    },
  }
}
</script>

<style scoped>
h6 {
  text-align: center;
  margin: 5px;
}
::v-deep(.delete-mark)
{
  background-color: lightgrey !important
}
</style>
