<template>
  <div>
    <div v-if="userId" class="w-full md:w-10 mx-auto">
      <Toast position="top-center" group="info" />

      <label for="fName" class="block text-900 text-xl font-medium mb-2"
        >Изменить фамилию</label
      >
      <InputText
        id="fName"
        v-model="fName"
        type="text"
        class="w-full mb-3"
        placeholder="Новая фамилия"
        style="padding: 1rem"
      />

      <label for="iName" class="block text-900 text-xl font-medium mb-2"
        >Изменить имя</label
      >
      <InputText
        id="iName"
        v-model="iName"
        type="text"
        class="w-full mb-3"
        placeholder="Новое имя"
        style="padding: 1rem"
      />

      <label for="oName" class="block text-900 text-xl font-medium mb-2"
        >Изменить отчество</label
      >
      <InputText
        id="oName"
        v-model="oName"
        type="text"
        class="w-full mb-3"
        placeholder="Новое отчество"
        style="padding: 1rem"
      />
      <Button
        @click="patchUser"
        :disabled="isFormRegisterConfirm === false"
        label="Сохранить изменения"
        class="w-full p-3 text-xl"
      ></Button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditUserData',
  data() {
    return {
      fName: '',
      iName: '',
      oName: '',
      message: '',
    }
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    async patchUser() {
      console.log('start');
        const formData = this.getFormData()
        console.log('form', formData);
        const headers = {
          'accept': 'application/json',
          'Content-Type': 'application/merge-patch+json',
        }
        try {
          const result = await this.axios.patch(
            `/api/users/${this.userId}`,
            JSON.stringify(formData),
            {
              headers,
            }
          )
          console.log(result)
          this.showSuccess()
        } catch (error) {
          if (error.response.status === 400) {
            this.message = 'Invalid input'
            this.showError()
          } else if (error.response.status === 422) {
            this.message = 'Unprocessable entity'
            this.showError()
          } else {
            this.message = 'Resource not found'
            this.showError()
          }
        }
    },
    getFormData() {
      const formData = new FormData()
      if (this.fName.length > 0 && this.fName !== this.user.fName) {
        formData.fName = this.fName
      }
      if (this.iName.length > 0 && this.iName !== this.user.iName) {
        formData.iName = this.iName
      }
      if (this.oName.length > 0 && this.oName !== this.user.oName) {
        formData.oName = this.oName
      }
      return formData
    },
    showSuccess() {
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'User resource updated',
        group: 'info',
        life: 4000,
      })
    },
    showError() {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: this.message,
        group: 'info',
        life: 4000,
      })
    },
  },
  computed: {
    isFormRegisterConfirm() {
      return (
        this.fName.length > 0 || this.iName.length > 0 || this.oName.length > 0
      )
    },
  },
}
</script>

<style scoped></style>
