<template>
  <div
    class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
      <div class="col-12 mt-5 xl:mt-0 text-center">
        <img
          :src="'layout/images/logo-' + logoColor + '.svg'"
          alt="Sakai logo"
          class="mb-5"
          style="width: 81px; height: 60px"
        />
      </div>
      <div
        class="col-12 xl:col-6"
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color),
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="h-full w-full m-0 py-7 px-4"
          style="
            border-radius: 53px;
            background: linear-gradient(
              180deg,
              var(--surface-50) 38.9%,
              var(--surface-0)
            );
          "
        >
          <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">Autentic</div>
            <span class="text-600 font-medium"
              >Enter Token from Google Authenticator</span
            >
            <h2>Потом убирется верх и боковые меню</h2>
          </div>
          <div class="w-full md:w-10 mx-auto">
            <div>
              <div class="links">
                <InputNumber class="items" id="authenticator" v-model="token" />
                <Button class="button items" label="Enter" @click="verify2fa" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import speakeasy from 'speakeasy'

export default {
  name: 'Login2fa',
  data() {
    return {
      token: null,
      user: {},
    }
  },
  mounted() {
    this.getUser()
    console.log('User AUTH', this.$auth.user())
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return 'white'
      return 'dark'
    },
  },
  methods: {
    verify2fa() {
      const response = speakeasy.totp.verify({
        secret: this.user.token2fa,
        encoding: 'ascii',
        token: this.token,
      })
      if (response) {
        console.log('response', response)
        console.log('Next step - AUTH Sign in = true')
      }
      if (!response) {
        console.log('response', response)
      }
    },
    async getUser() {
      const userId = 48 // временно пока не будет auth давать id как число на не мыло

      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const resp = await this.axios.get(`/api/users/${userId}`, { headers })
      const user = resp.data
      this.user = user
      console.log('User API', user)
    },
  },
}
</script>

<style scoped></style>
