<template>
  <div>
    <div class="flex justify-content-between">
      <Toast position="top-center" group="info" />
      <h2 class="flex align-items-center">Hi, {{ user.iName }} {{ user.fName }} </h2>
      <span class="flex align-items-center green" v-if="user.is2FaEnabled">2FA is enabled</span>
      <span class="flex align-items-center red" v-else>2FA is not enabled</span>
    </div>

    <div class="card">
      <TabView ref="tabview">
        <TabPanel header="Изменить пароль">
          <EditUserPassword
          class="wrapp"
          :userId="user.id" />
        </TabPanel>
        <TabPanel header="Изменить данные">
          <EditUserData
          class="wrapp"
          :user="user" :userId="user.id" />
        </TabPanel>
        <TabPanel header="Изменить 2FA">
          <Edit2fa
          class="wrapp"/>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data() {
    return {
      user: {}
    };
  },
  mounted() {
    this.getUser()
  },
  methods: {
    async getUser() {
      const userId = await this.$auth.user().id
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const resp = await this.axios.get(`/api/users/${userId}`, { headers })
      const user = resp.data
      this.user = user
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return 'white'
      return 'dark'
    },
  }
}
</script>

<style scope>

.wrapp {
  margin-top: 10px;
}
.button {
  margin: 5px;
}
.img {
  text-align: center;
}
.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
}
.items {
  margin: 0 5px;
}
.green {
  color: green;
}
.red {
  color: red;
}
</style>
