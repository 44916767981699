<template>
  <div class="trade-limit">
    <Toast position="top-center" group="info" />
    <hr>

    <div class="block-limit">
      <div class='p-inputgroup'>
        <Button
          :label='getBuyBalance'
          class='p-button-text p-button-secondary p-button-sm p-link'
        />
      </div>

      <div class='p-inputgroup'>
        <Button
          :label='getSaleBalance'
          class='p-button-text p-button-secondary p-button-sm p-link'
          @click='fillValueSale'
        />
      </div>
    </div>
    <hr>

    <div class="block-limit">
      <div class="p-inputgroup">
        <label for='priceLimitBuyId' class='LabelText'>Price Buy</label>
        <div>
          <InputText
            v-model="priceLimitBuy"
            id="priceLimitBuyId"
            class="input m-1"
            mode="currency"
            locale="en-US"
            currency="USD"
            placeholder='0'
            type='number'
          />
          <span class='watermark'>{{ this.tradeMarket.mnemonic.tokenQuote }}</span>
        </div>
        <label for='valueLimitBuyId' class='LabelText'>Value Buy</label>
        <div>
          <InputText
            v-model="valueLimitBuy"
            id="valueLimitBuyId"
            class="input m-1"
            placeholder='0'
            type='number'
          />
          <span class='watermark'>{{ this.tradeMarket.mnemonic.token }}</span>
        </div>
        <p v-if="!allFieldBuy" class="p-error mx-2">
          Empty field, too large or incorrect price
        </p>
        <p v-else><br></p>
        <Button
          :disabled="!allFieldBuy"
          class="btn p-button-success"
          label="Buy"
          @click="buy"
        />
      </div>

      <hr />

      <div class="p-inputgroup">
        <label for='priceLimitSaleId' class='LabelText'>Price Sale</label>
        <div>
          <InputText
            v-model="priceLimitSale"
            id="priceLimitSaleId"
            class="input m-1"
            placeholder='0'
            type='number'
          />
          <span class='watermark'>{{this.tradeMarket.mnemonic.tokenQuote}}</span>
        </div>
        <label for='valueLimitSaleId' class='LabelText'>Value Sale</label>
        <div>
          <InputText
            class="input m-1"
            v-model="valueLimitSale"
            id="valueLimitSaleId"
            placeholder='0'
            type='number'
          />
          <span class='watermark'>{{tradeMarket.mnemonic.token}}</span>
        </div>
        <p v-if="!allFieldSale" class="p-error mx-2">
          Empty field, too large or incorrect value
        </p>
        <p v-else><br></p>
        <Button
          :disabled="!allFieldSale"
          class="btn p-button-danger"
          label="Sale"
          @click="sale"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tradeMarket: {
      type: Object,
    },
  },

  data() {
    return {
      priceLimitBuy: '',
      valueLimitBuy: '',
      priceLimitSale: '',
      valueLimitSale: '',

      limitBuy: {
        priceLimitBuy: '',
        valueLimitBuy: '',
      },
      limitSale: {
        priceLimitSale: '',
        valueLimitSale: '',
      },
    }
  },
  methods: {
    async buy() {
      console.log('buy')
      this.limitBuy.priceLimitBuy = this.priceLimitBuy
      this.limitBuy.valueLimitBuy = this.valueLimitBuy

      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const params = {
        type: 'token_order_limit_create',
        token: `/api/au_tokens/${this.tradeMarket.id.token}`,
        user: `/api/users/${this.$auth.user().id}`,
        description: this.tradeMarket.mnemonic.token + '/' + this.tradeMarket.mnemonic.tokenQuote,
        value: this.valueLimitBuy,
        address: '',
        isTask: true,
        isTaskDone: false,
        mode: 'buy',
        priceString: this.priceLimitBuy,
        tokenQuote: `/api/au_tokens/${this.tradeMarket.id.tokenQuote}`
      }

      try{
        await this.axios.post('/api/token_events', params, headers);
        this.$emit('update-orders');
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: `${this.valueLimitBuy} Tokens buy for ${
            this.valueLimitBuy * this.priceLimitBuy}
          ${this.tradeMarket.mnemonic.tokenQuote}`,
          group: 'info',
          life: 3000,
        })
      }
      catch (e){
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Request not created',
          group: 'info',
          life: 3000,
        })
      }
      this.priceLimitSale = ''
      this.valueLimitSale = ''
    },

    async sale() {
      console.log('sale')
      this.limitSale.priceLimitSale = this.priceLimitSale
      this.limitSale.valueLimitSale = this.valueLimitSale

      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const params = {
        type: 'token_order_limit_create',
        token: `/api/au_tokens/${this.tradeMarket.id.token}`,
        user: `/api/users/${this.$auth.user().id}`,
        description: this.tradeMarket.mnemonic.token + '/' + this.tradeMarket.mnemonic.tokenQuote,
        value: this.valueLimitSale,
        address: '',
        isTask: true,
        isTaskDone: false,
        mode: 'sale',
        priceString: this.priceLimitSale,
        tokenQuote: `/api/au_tokens/${this.tradeMarket.id.tokenQuote}`
      }

      try{
        await this.axios.post('/api/token_events', params, headers);
        this.$emit('update-orders');
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: `${this.valueLimitSale} Tokens sale for ${
            this.valueLimitSale * this.priceLimitSale}
          ${this.tradeMarket.mnemonic.tokenQuote}`,
          group: 'info',
          life: 3000,
        })
      }
      catch (e){
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Request not created',
          group: 'info',
          life: 3000,
        })
      }
      this.priceLimitSale = ''
      this.valueLimitSale = ''
    },

    fillValueSale()
    {
      this.valueLimitSale = this.tradeMarket.limitHave.valueHave - this.tradeMarket.limitHave.tokenBlocked;
    },
  },
  computed: {
    allFieldBuy() {
      return (
        +this.priceLimitBuy <= this.tokenQuoteHave &&
        +this.priceLimitBuy >= 0 &&
        +this.valueLimitBuy >= 0
      )
    },
    allFieldSale() {
      return (
        +this.priceLimitSale >= 0 &&
        +this.valueLimitSale <= this.tokenHave &&
        +this.valueLimitSale >= 0
      )
    },
    getSaleBalance()
    {
      return `Доступно: ${this.tokenHave}`
        + ` из ${this.tradeMarket.limitHave.valueHave}` + ` ${this.tradeMarket.mnemonic.token}`;
    },
    getBuyBalance()
    {
      return `Доступно: ${this.tokenQuoteHave}`
        + ` из ${this.tradeMarket.limitHave.moneyHave}` + ` ${this.tradeMarket.mnemonic.tokenQuote}`;
    },
    tokenHave()
    {
      return this.tradeMarket.limitHave.valueHave - this.tradeMarket.limitHave.tokenBlocked;
    },
    tokenQuoteHave()
    {
      return this.tradeMarket.limitHave.moneyHave - this.tradeMarket.limitHave.tokenQuoteBlocked;
    }
  },
}
</script>

<style scoped>
h6 {
  text-align: center;
  margin: 5px;
}
.watermark{
  font-size: 1.5em;
  color: grey;
  position: relative;
  right: 65px;
}
.block-limit {
  display: flex;
  flex-wrap: nowrap;
}
.p-inputgroup {
  display: inline-block;
}
.input {
  width: 80%;
}
.btn {
  margin: 4px;
  width: 80%;
  border-radius: 6px;
}
.LabelText{
  flex-basis: 50%;
  font-style: normal;
  font-size: smaller;
}
</style>
