<template>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-3">
      <h1>Test</h1>
      <Button label="test" @click="test" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Test",
  methods: {
    test() {
      console.log('check()')
      console.log(this.$auth.check())
      console.log('check(\'admin\')')
      console.log(this.$auth.check('admin'))

      console.log('check(\'user\')')
      console.log(this.$auth.check('user'))

      console.log('check(\'developer\')')
      console.log(this.$auth.check('developer'))

      console.log('this.$auth.user()')
      console.log(this.$auth.user())

      console.log('this.$auth.user().id')
      console.log(this.$auth.user().id)

      this.axios.get('/api/');
    },
  }
}
</script>

<style scoped>

</style>
