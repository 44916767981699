<template>
  <div>
    <div class="w-full md:w-10 mx-auto">
      <Toast position="top-center" group="info" />
      <div
        class="font-medium mb-2 text-dm"
        style="color: red"
        v-for="error of v$.$errors"
        :key="error.$uid"
      >
        <small>{{ error.$message }}</small>
        <small v-if="error.$validator === 'noStrong'"
          >{{ error.$message }} The password is weak</small
        >
      </div>

      <label for="password" class="block text-900 font-medium text-xl mb-2"
        >Изменить пароль</label
      >
      <Password
        id="password"
        v-model.trim="password"
        placeholder="Новый пароль"
        :toggleMask="true"
        class="w-full mb-3"
        inputClass="w-full"
        inputStyle="padding:1rem"
      >
      </Password>

      <label for="password_confirm" class="block text-900 font-medium mb-2"
        >Подтверждение пароля
      </label>
      <Password
        id="password_confirm"
        v-model="password_confirm"
        placeholder="Подтвердите новый пароль"
        :toggleMask="true"
        :feedback="false"
        class="w-full mb-3"
        inputClass="w-full"
        inputStyle="padding:1rem"
      >
      </Password>

      <p
        v-if="!isPasswordConfirm"
        class="font-medium text-sm"
        style="color: red"
      >
        Пароли не совпадают
      </p>
      <p v-else class="font-medium text-sm" style="color: green">
        Пароли совпадают
      </p>

      <Button
        @click="patchUser"
        :disabled="isFormRegisterConfirm === false"
        label="Сохранить изменения"
        class="w-full p-3 text-xl"
      ></Button>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
export default {
  name: 'EditUserPassword',
  data() {
    return {
      v$: useVuelidate(),
      password: '',
      password_confirm: '',
      passwordMismatch: '',
      message: ''
    }
  },
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8),
        noStrong: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          // const containsSpecial = /[#?!@$%^&*-]/.test(value) // Если нужно усилить пароль
          return (
            containsUppercase && containsLowercase && containsNumber
            // && containsSpecial
          )
        },
      },
    }
  },
  methods: {
    async patchUser() {
      console.log('start', this.userId);
      // Раскоментировать когда на сервере заработает смена пароля
      // this.v$.$validate()
      // if (!this.v$.$error) {
      //   const formData = {
      //     password: this.password,
      //   }
      //   const headers = {
      //     accept: 'application/json',
      //     'Content-Type': 'application/merge-patch+json',
      //   }
      //   try {
      //     const result = await this.axios.patch(
      //       `/api/users/${this.userId}`,
      //       formData,
      //       {
      //         headers,
      //       }
      //     )
      //     console.log(result)
      //     this.showSuccess()
      //   } catch (error) {

      //     if (error.response.status === 400) {
      //       this.message = 'Invalid input'
      //       this.showError()
      //     } else if(error.response.status === 422) {
      //       this.message = 'Unprocessable entity'
      //       this.showError()
      //     } else {
      //       this.message = 'Resource not found'
      //       this.showError()
      //     }
      //     console.log(error)
      //   }
      // }
    },
    showSuccess() {
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'User resource updated',
        group: 'info',
        life: 4000,
      })
    },
    showError() {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: this.message,
        group: 'info',
        life: 4000,
      })
    },
  },
  computed: {
    isPasswordConfirm() {
      return (
        this.password.length >= 8 && this.password === this.password_confirm
      )
    },
    isFormRegisterConfirm() {
      return (
        this.password.length >= 8 &&
        this.password === this.password_confirm &&
        !this.v$.$error
      )
    },
  },
  watch: {
    password() {
      this.v$.$touch()
      return
    },
    password_confirm() {
      if (this.password === this.password_confirm) {
        this.passwordMismatch = false
      }
    },
  },
}
</script>

<style scoped></style>
