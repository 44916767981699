import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'prismjs/themes/prism-coy.css'
import './assets/styles/layout.scss'
import './assets/demo/flags/flags.css'
import './assets/styles/sass/_data.scss'

import { createApp, reactive } from 'vue'
import router from './router'
import AppWrapper from './AppWrapper.vue'
import PrimeVue from 'primevue/config'
import AutoComplete from 'primevue/autocomplete'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
import Breadcrumb from 'primevue/breadcrumb'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import Carousel from 'primevue/carousel'
import Chart from 'primevue/chart'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Chips from 'primevue/chips'
import ColorPicker from 'primevue/colorpicker'
import Column from 'primevue/column'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmationService from 'primevue/confirmationservice'
import ContextMenu from 'primevue/contextmenu'
import DataTable from 'primevue/datatable'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import Fieldset from 'primevue/fieldset'
import FileUpload from 'primevue/fileupload'
import Image from 'primevue/image'
import InlineMessage from 'primevue/inlinemessage'
import Inplace from 'primevue/inplace'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import Knob from 'primevue/knob'
import Galleria from 'primevue/galleria'
import Listbox from 'primevue/listbox'
import MegaMenu from 'primevue/megamenu'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
import OrderList from 'primevue/orderlist'
import OrganizationChart from 'primevue/organizationchart'
import OverlayPanel from 'primevue/overlaypanel'
import Paginator from 'primevue/paginator'
import Panel from 'primevue/panel'
import PanelMenu from 'primevue/panelmenu'
import Password from 'primevue/password'
import PickList from 'primevue/picklist'
import ProgressBar from 'primevue/progressbar'
import Rating from 'primevue/rating'
import RadioButton from 'primevue/radiobutton'
import Ripple from 'primevue/ripple'
import SelectButton from 'primevue/selectbutton'
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop'
import Slider from 'primevue/slider'
import Sidebar from 'primevue/sidebar'
import Skeleton from 'primevue/skeleton'
import SplitButton from 'primevue/splitbutton'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Steps from 'primevue/steps'
import StyleClass from 'primevue/styleclass'
import TabMenu from 'primevue/tabmenu'
import Tag from 'primevue/tag'
import TieredMenu from 'primevue/tieredmenu'
import Textarea from 'primevue/textarea'
import Timeline from 'primevue/timeline'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Toolbar from 'primevue/toolbar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Tooltip from 'primevue/tooltip'
import ToggleButton from 'primevue/togglebutton'
import Tree from 'primevue/tree'
import TreeSelect from 'primevue/treeselect'
import TreeTable from 'primevue/treetable'
import TriStateCheckbox from 'primevue/tristatecheckbox'

import CodeHighlight from './AppCodeHighlight'
import BlockViewer from './BlockViewer'

import EditUserData from './components/forms/EditUserData'
import EditUserPassword from './components/forms/EditUserPassword'
import Edit2fa from './components/forms/Edit2fa'
import EditCompany from './components/edit/EditCompany'
import TradeList from './components/trading/TradeList'
import TradeChart from './components/trading/TradeChart'
import TradePairs from './components/trading/TradePairs'
import TradeHistory from './components/trading/TradeHistory'
import TradeMarket from './components/trading/TradeMarket'
import TradeLimit from './components/trading/TradeLimit'

import TradeView from './components/trading/TradeView'

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0)
  next()
})

// import Websocket from 'vue-native-websocket-vue3'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'

import { createAuth } from '@websanova/vue-auth'
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'

// https://websanova.com/docs/vue-auth/methods/core#login
let auth = createAuth({
  plugins: {
    http: axios,
    router: router,
  },
  drivers: {
    http: driverHttpAxios,
    auth: driverAuthBearer,
    router: driverRouterVueRouter,
  },
  options: {
    rolesKey: 'type',
    // notFoundRedirect: {name: 'user-account'},
    tokenDefaultKey: 'access_token',
    stores: ['storage', 'cookie'],
  },
})

const app = createApp(AppWrapper)

app.config.globalProperties.$appState = reactive({
  theme: 'lara-light-indigo',
  darkTheme: false,
})

app.config.globalProperties.$filters = {
  correctNumber(number) {
    return /^[0-9.]+$/.test(number)
  },
  correctETH(eth) {
    return /^0x[a-fA-F0-9]{40}$/.test(eth)
  },
  correctMnemonic(Mnemonic) {
    return /^[a-zA-Z-.]{2,6}$/.test(Mnemonic)
  },
  shortAddress(address) {
    return address ? address.substr(0, 5) + '...' + address.substr(-5) : ''
  },
  correctURL(url) {
    return /(^(http|https):\/\/[-a-zA-Z0-9_]{2,256}\.[-a-zA-Z0-9_/]{2,25}$)|(^www\.[-a-zA-Z0-9_]{2,256}\.[-a-zA-Z0-9_/]{2,25}$)/.test(
      url
    )
  },
  languageEN(text) {
    return /^[-a-zA-Z 0-9!_@;#:%$"*'-+.,?)=(<>/]+$/.test(text)
  },
}

// app.config.globalProperties.$appState = reactive({ theme: 'lara-dark-blue', darkTheme: true });

// app.use(Websocket, process.env.VUE_APP_WEBSOCKET, {reconnection:true, format: "json"})
app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' })
app.use(ConfirmationService)
app.use(ToastService)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(auth)

// app.use(useVuelidate);

// app.axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://stb-api.8al.ru' : 'http://localhost:8000';
console.log(process.env.VUE_APP_HOST_API)
app.axios.defaults.baseURL = process.env.VUE_APP_HOST_API

app.directive('tooltip', Tooltip)
app.directive('ripple', Ripple)
app.directive('code', CodeHighlight)
app.directive('badge', BadgeDirective)
app.directive('styleclass', StyleClass)

app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('AutoComplete', AutoComplete)
app.component('Avatar', Avatar)
app.component('AvatarGroup', AvatarGroup)
app.component('Badge', Badge)
app.component('Breadcrumb', Breadcrumb)
app.component('Button', Button)
app.component('Calendar', Calendar)
app.component('Card', Card)
app.component('Carousel', Carousel)
app.component('Chart', Chart)
app.component('Checkbox', Checkbox)
app.component('Chip', Chip)
app.component('Chips', Chips)
app.component('ColorPicker', ColorPicker)
app.component('Column', Column)
app.component('ConfirmDialog', ConfirmDialog)
app.component('ConfirmPopup', ConfirmPopup)
app.component('ContextMenu', ContextMenu)
app.component('DataTable', DataTable)
app.component('DataView', DataView)
app.component('DataViewLayoutOptions', DataViewLayoutOptions)
app.component('Dialog', Dialog)
app.component('Divider', Divider)
app.component('Dropdown', Dropdown)
app.component('Fieldset', Fieldset)
app.component('FileUpload', FileUpload)
app.component('Image', Image)
app.component('InlineMessage', InlineMessage)
app.component('Inplace', Inplace)
app.component('InputMask', InputMask)
app.component('InputNumber', InputNumber)
app.component('InputSwitch', InputSwitch)
app.component('InputText', InputText)
app.component('Galleria', Galleria)
app.component('Knob', Knob)
app.component('Listbox', Listbox)
app.component('MegaMenu', MegaMenu)
app.component('Menu', Menu)
app.component('Menubar', Menubar)
app.component('Message', Message)
app.component('MultiSelect', MultiSelect)
app.component('OrderList', OrderList)
app.component('OrganizationChart', OrganizationChart)
app.component('OverlayPanel', OverlayPanel)
app.component('Paginator', Paginator)
app.component('Panel', Panel)
app.component('PanelMenu', PanelMenu)
app.component('Password', Password)
app.component('PickList', PickList)
app.component('ProgressBar', ProgressBar)
app.component('RadioButton', RadioButton)
app.component('Rating', Rating)
app.component('SelectButton', SelectButton)
app.component('ScrollPanel', ScrollPanel)
app.component('ScrollTop', ScrollTop)
app.component('Slider', Slider)
app.component('Sidebar', Sidebar)
app.component('Skeleton', Skeleton)
app.component('SplitButton', SplitButton)
app.component('Splitter', Splitter)
app.component('SplitterPanel', SplitterPanel)
app.component('Steps', Steps)
app.component('TabMenu', TabMenu)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Tag', Tag)
app.component('Textarea', Textarea)
app.component('TieredMenu', TieredMenu)
app.component('Timeline', Timeline)
app.component('Toast', Toast)
app.component('Toolbar', Toolbar)
app.component('ToggleButton', ToggleButton)
app.component('Tree', Tree)
app.component('TreeSelect', TreeSelect)
app.component('TreeTable', TreeTable)
app.component('TriStateCheckbox', TriStateCheckbox)

app.component('BlockViewer', BlockViewer)
app.component('EditUserData', EditUserData)
app.component('EditUserPassword', EditUserPassword)
app.component('Edit2fa', Edit2fa)
app.component('EditCompany', EditCompany)
app.component('TradeList', TradeList)
app.component('TradeChart', TradeChart)
app.component('TradePairs', TradePairs)
app.component('TradeHistory', TradeHistory)
app.component('TradeMarket', TradeMarket)
app.component('TradeLimit', TradeLimit)

app.component('TradeView', TradeView)
app.mount('#app')
