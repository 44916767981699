<template>
  <div
    class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 20%">
      <div class="col-12 mt-1 xl:mt-0 text-center">
        <img
          :src="'../layout/images/logo-' + logoColor + '.svg'"
          alt="Sakai logo"
          class="mb-5"
          style="width: 81px; height: 60px"
        />
      </div>

      <div v-if="isSuccess" class="text-center mb-5">
        <div class="text-3xl font-medium mb-3" style="color: green">
          Thank for registering!
        </div>
        <span class="text-600 font-medium"
          >The e-mail is confirmed. You can
        </span>
        <router-link :to="'/login'">Login</router-link>
      </div>
      <div v-if="isError" class="text-center mb-5">
        <p class="text-3xl font-medium mb-3" style="color: red">
          The e-mail is not confirmed!
        </p>
        <span class="text-600 font-medium">Try to </span>
        <router-link :to="'/register'">Register</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSuccess: false,
      isError: false,
    }
  },
  created() {
    this.checkEmail()
  },
  methods: {
    async checkEmail() {
      const hash = this.$route.params.hash
      const headers = {
        accept: '*/*',
        'Content-Type': 'application/json',
      }
      const params = {
        token: `${hash}`,
      }
      try {
        const resp = await this.axios.post(
          'api/users/email_verify',
          params,
          headers
        )
        this.isError = false
        this.isSuccess = true
        console.log('resp', resp)
      } catch (e) {
        if (e.response.status === 400) {
          this.isError = true
          this.isSuccess = false
          console.log('err', e.response)
        }
      }
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) {
        return 'white'
      } else {
        return 'dark'
      }
    },
  },
}
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>
