<template>
  <div>
    <div class="card">
      <Toast position="top-center" group="info" />
      <div class="new-eth">
        <h5>Создать Адрес</h5>
        <div class="p-inputgroup mb-1">
          <InputText placeholder="ETH адрес" v-model="ethNew" />
          <Button
            :disabled="!$filters.correctETH(ethNew)"
            icon="pi pi-check"
            class="p-button-success"
            @click="createEth"
          />
        </div>
        <small class="p-error ml-1" v-if="!$filters.correctETH(ethNew)"
          >Адрес не правильный или поле пустое</small
        >
      </div>
      <hr />
      <div v-if="ethWallets.length" class="my-eth">
        <DataTable
          :value="ethWallets"
          dataKey="id"
          v-model:ethWallets="ethWallets"
          responsiveLayout="scroll"
          paginator
          lazy
          :rows="rows"
          :totalRecords="totalItems"
          @page="setPage"
        >
          <Column style="word-break: break-all" header="Адреса" field="value">
          </Column>
          <Column
            class="bg-white border-none flex flex-nowrap justify-content-end"
          >
            <template #body="slot">
              <Button
                v-if="!slot.data.verifiedAt"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning m-1"
                @click="edit(slot.data)"
              />
              <Button
                label="В реестр"
                class="p-button-raised p-button-rounded p-button-secondary m-1"
                @click="trust(slot.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger m-1"
                @click="del(slot.data)"
              />
              <Button
                v-if="slot.data.verifiedAt"
                label="VERIFY"
                class="p-button-raised p-button-rounded p-button-success m-1"
                disabled="disabled"
              />
              <Button
                v-if="!slot.data.verifiedAt"
                label="VERIFY"
                class="p-button-raised p-button-rounded p-button-danger m-1"
                @click="
                  () => $router.push(`/adresses/${slot.data.value}/validate`)
                "
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else class="no-eth">
        <p>У вас еще нет ETH адресов</p>
      </div>
    </div>
    <!-- Dialogs -->
    <Dialog
      v-model:visible="editDialog"
      :style="{ width: '450px' }"
      header="Edit ETH"
      class="p-fluid"
    >
      <div class="field">
        <label for="name">Введите новый ETH адрес</label>
        <InputText id="name" required="true" v-model="ethEdit" autofocus />
        <small class="p-error" v-if="!$filters.correctETH(ethEdit)"
          >Адрес не правильный или поле пустое</small
        >
      </div>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="editDialog = false"
        />
        <Button
          :disabled="!$filters.correctETH(ethEdit)"
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="editConfirm"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error" v-if="ethEdit">
          Are you sure you want to delete ETH
        </p>
        <p>
          <b>{{ ethEdit.eth }}</b>
        </p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteConfirm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ethWallets: {},
      userId: this.$auth.$vm.state.data.id,
      editDialog: false,
      deleteDialog: false,
      ethNew: '',
      ethEdit: '',
      ethId: '',
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },
  async mounted() {
    this.getEth()
  },
  methods: {
    setPage(event) {
      this.page = event.page + 1
      this.getEth()
    },
    async getEth() {
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
      }
      try {
        const resp = await this.axios.get('api/eth_addresses', {
          headers,
          params,
        })
        this.ethWallets = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in api/eth_addresses api call',
          group: 'info',
          life: 3000,
        })
      }
    },
    async createEth() {
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const params = {
        value: this.ethNew.toLowerCase(),
        user: `/api/users/${this.userId}`,
      }
      try {
        await this.axios.post('api/eth_addresses', params, headers)
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'ETH created',
          group: 'info',
          life: 3000,
        })
        this.getEth()
        this.ethNew = ''
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'ETH not created',
          group: 'info',
          life: 3000,
        })
      }
    },
    edit(value) {
      this.ethEdit = value.value
      this.ethId = value.id
      this.editDialog = true
    },
    async editConfirm() {
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const params = {
        value: this.ethEdit.toLowerCase(),
        user: `/api/users/${this.userId}`,
      }
      try {
        await this.axios.put(`api/eth_addresses/${this.ethId}`, params, headers)
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'ETH edited',
          group: 'info',
          life: 3000,
        })
        this.editDialog = false
        this.getEth()
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'ETH not edited',
          group: 'info',
          life: 3000,
        })
      }
    },
    del(value) {
      this.ethEdit = value
      this.deleteDialog = true
    },
    async deleteConfirm() {
      const ethId = this.ethEdit.id

      const headers = {
        accept: '*/*',
      }
      try {
        await this.axios.delete(`api/eth_addresses/${ethId}`, {
          headers: headers,
        })
        this.deleteDialog = false
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'ETH deleted',
          group: 'info',
          life: 3000,
        })
        this.getEth()
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'ETH not delete',
          group: 'info',
          life: 3000,
        })
      }
    },

    async trust(value) {
      try {
        const headers = {
          accept: 'application/json',
          'Content-Type': 'application/merge-patch+json',
        }
        const params = {
          status: 'add',
        }
        await this.axios.patch(`api/eth_addresses/${value.id}`, params, {
          headers: headers,
        })
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'ETH added to the list of trusted',
          group: 'info',
          life: 3000,
        })
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error adding to the list of trusted',
          group: 'info',
          life: 3000,
        })
      }
    },
  },
}
</script>

<style scoped></style>
