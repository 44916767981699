<template>
<div>
  <div class="TVChartContainer" ref="chart" />
</div>
</template>

<script>
import { widget } from '../../../public/tradingview/charting_library/charting_library'

export default {
  name: "tv",
  tvWidget: null,
  props: {
    ticker: {
      default: null,
      type: Object
    }
  },

  data()
  {
    return {
      myDatafeed: {},
      configuration: {
          supported_resolutions: [
            '1',
            '3',
            '5',
            '15',
            '30',
            '60',
            '240',
            '720',
            '1D'
          ],
      },
    }
  },

  mounted()
  {
    this.myDatafeed.onReady = this.onReady;
    this.myDatafeed.resolveSymbol = this.resolveSymbol;
    this.myDatafeed.getBars = this.getBars;
    this.myDatafeed.subscribeBars = this.subscribeBars;
    this.myDatafeed.unsubscribeBars = this.unsubscribeBars;

    const container = this.$refs.chart;
    const widgetOptions = {
      symbol: 'AAPL',
      locale: 'ru',
      datafeed: this.myDatafeed,
      interval: 'D',
      container: container,
      library_path: '/tradingview/charting_library/',
      disabled_features: ['use_localstorage_for_settings'],
      enabled_features: ['study_templates'],
      charts_storage_url: 'https://saveload.tradingview.com',
      charts_storage_api_version: '1.1',
      client_id: 'tradingview.com',
      user_id: 'public_user_id',
      fullscreen: false,
      autosize: true,
    }
    this.tvWidget = new widget(widgetOptions);
  },

  unmounted () {
    if (this.tvWidget) {
      this.tvWidget.remove()
      this.tvWidget = null
    }
  },

  methods: {
    onReady(callback)
    {
      setTimeout(()=> callback(this.configuration), 0);
    },

    resolveSymbol (symbolName, onSymbolResolvedCallback)
    {
      const symbolInfo = {
        ticker: 'BTC/GPD',
        name: `Test symbol`,
        description: `Test symbol description`,
        type: 'crypto',
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: 'PancakeSwap',
        minmov: 1,
        pricescale: 100000000,
        has_intraday: true,
        has_no_volume: true,
        supported_resolutions: this.configuration.supported_resolutions,
        volume_precision: 2,
        data_status: 'streaming',
      }
      return setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
    },

    async getBars (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback)
    {
      const { countBack } = periodParams;

      try
      {
        let bars = [];
        const resp = await this.axios.get('/api/candles?page=1&itemsPerPage=100');
        const barsData = resp.data;
        let lastBar = {};
        for (const item of barsData)
        {
          lastBar = {
            time: Date.parse(item.startTime),
            low: item.l,
            high: item.h,
            open: item.o,
            close: item.c,
            volume: item.v,
          };
          bars.push(lastBar);
        }
        if (bars.length === 0 || bars.length < countBack) onHistoryCallback([], { noData: true })
        else {
          onHistoryCallback(bars, { noData: false });
          this.$store.commit('setLastBar', lastBar);
        }
      }
      catch (error)
      {
        onErrorCallback(error);
      }
    },

    async subscribeBars (symbolInfo, resolution, onRealtimeCallback)
    {
      this.$store.commit('setCallback', onRealtimeCallback);
    },

    async unsubscribeBars (subscriberUID)
    {
      console.log('unsubscribeBars start', subscriberUID);
    },
  }
}
</script>

<style lang="scss" scoped>
.TVChartContainer {
  // TODO  height: calc(100vh);
  // height: 600px
  height: 60vh
}
</style>
