<template>
  <Landing v-if="$route.path === '/landing'" @change-theme="changeTheme" />
  <Login v-else-if="$route.path === '/login'" />
  <Register v-else-if="$route.path === '/register'" />
  <NotFound v-else-if="$route.path === '/notfound'" />
  <Access v-else-if="$route.path === '/access'" />
  <Error v-else-if="$route.path === '/404'" />
  <Verify
    v-else-if="$route.path === $router.options.history.location && path"
  />
  <!-- старая версия -->
  <!-- <Verify v-else-if="$route.path === '/email_verify'" /> -->
  <!-- <App v-else /> -->

  <App v-else @change-theme="changeTheme" />

  <!-- Не работает на 100% -->
  <!-- <App v-else-if="this.$auth.user()" @change-theme="changeTheme" /> -->
  <!-- <Error v-else @change-theme="changeTheme" /> -->
</template>

<script>
import EventBus from './AppEventBus'
import App from './App'
import Landing from './pages/LandingDemo'
import Login from './pages/Login'
import Error from './pages/Error'
import NotFound from './pages/NotFound'
import Access from './pages/Access'
import Verify from './pages/EmailVerify'
import Register from './pages/Register'

export default {
  data() {
    return {
      path: false,
    }
  },
  created() {
    this.checkPath()
  },
  methods: {
    changeTheme(event) {
      let themeElement = document.getElementById('theme-link')
      themeElement.setAttribute(
        'href',
        themeElement
          .getAttribute('href')
          .replace(this.$appState.theme, event.theme)
      )
      this.$appState.theme = event.theme
      this.$appState.darkTheme = event.dark
      EventBus.emit('change-theme', event)

      if (event.theme.startsWith('md')) {
        this.$primevue.config.ripple = true
      }
    },
    checkPath() {
      let filter = this.$router.options.history.location
      let result = filter.substr(0, 13)
      if (result === '/email_verify') {
        this.path = true
      } else {
        this.path = false
      }
    },
  },
  components: {
    App,
    Landing,
    Login,
    Error,
    NotFound,
    Access,
    Verify,
    Register,
  },
}
</script>
