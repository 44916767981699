<template>
  <div
    class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
      <div class="col-12 mt-5 xl:mt-0 text-center">
        <img
          :src="'layout/images/logo-' + logoColor + '.svg'"
          alt="Sakai logo"
          class="mb-5"
          style="width: 81px; height: 60px"
        />
      </div>
      <div
        class="col-12 xl:col-6"
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color),
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="h-full w-full m-0 py-7 px-4"
          style="
            border-radius: 53px;
            background: linear-gradient(
              180deg,
              var(--surface-50) 38.9%,
              var(--surface-0)
            );
          ">
          <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">Autentic</div>
            <span class="text-600 font-medium">Sign in to continue</span>
          </div>

          <div class="w-full md:w-10 mx-auto">
            <div
            v-if="!is2FaEnabled"
            class="clos">
            <label for="email1" class="block text-900 text-xl font-medium mb-2"
              >Email</label
            >
            <InputText
              id="email1"
              v-model="email"
              type="text"
              class="w-full mb-3"
              placeholder="Email"
              style="padding: 1rem"
            />

            <label
              for="password1"
              class="block text-900 font-medium text-xl mb-2"
              >Password</label
            >
            <Password
              id="password1"
              :feedback="false"
              v-model="password"
              placeholder="Password"
              :toggleMask="true"
              class="w-full mb-3"
              inputClass="w-full"
              inputStyle="padding:1rem"
            >
            </Password>

            <div class="flex align-items-center justify-content-between mb-5">
              <div class="flex align-items-center">
                <Checkbox
                  id="rememberme1"
                  v-model="checked"
                  :binary="true"
                  class="mr-2"
                ></Checkbox>
                <label for="rememberme1">Remember me</label>
              </div>
              <a
                class="font-medium no-underline ml-2 text-right cursor-pointer"
                style="color: var(--primary-color)"
                >Forgot password?</a
              >
              <router-link
                class="font-medium no-underline ml-2 text-right cursor-pointer"
                style="color: var(--primary-color)"
                :to="'/register'"
                >Register</router-link
              >
            </div>
            <Button
              @click="signin"
              label="Sign In"
              class="w-full p-3 text-xl"
            ></Button>
          </div>

            <div
            v-if="is2FaEnabled"
            class="En2fa">
            <label
            for="authCode" class="block text-900 text-xl font-medium mb-2"
            >Enter Google Authenticator code</label
            >
            <InputText
                id="authCode"
                v-model="authCode"
                type="text"
                class="w-full mb-3"
                placeholder="Enter Google Authenticator code"
                style="padding: 1rem"
            />
            <p class="err">Please еnter correct code</p>
            <Button
              @click="signin"
              label="Enter code"
              class="w-full p-3 text-xl"
            ></Button>
          </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      checked: false,
      authCode: '',
      is2FaEnabled: false,
    }
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return 'white'
      return 'dark'
    },
  },
  methods: {
    signin() {
      const data = {
        email: this.email,
        password: this.password,
        authCode: this.authCode
      }
      this.$auth
        .login({
          data,
          // redirect: {name: 'user-account'},
          // redirect: null,
          // remember: 'Rob',
          staySignedIn: true,
          fetchUser: false,
          url: '/api/users/login'
        })
        .then(null, (res) => {
          if (res.response.data.errors === '2fa is enabled! Authcode failed!') {
            this.is2FaEnabled = true
          }
        })
        .catch((e) =>{
          console.log(e)
        })
      this.$auth.remember(this.email)
    },
  },
}
</script>

<style scoped>
.err {
  color: red;
  text-align: center;
}

</style>
