import { createStore } from 'vuex'

const store = createStore({
  state () {
    return {
      lastBar: null,
      callback: null
    }
  },
  mutations: {
    setLastBar(state, newLastBar) {
      state.lastBar = newLastBar;
    },
    setCallback(state, newCallback) {
      state.callback = newCallback;
    }
    }
})

export default store